import React from 'react';
import './Question.css'

const Question = ({ questionData, onSubmit }) => {

    const handleOptionClick = (option) => {
        onSubmit(option);
    };

    return (
        <div className='question-container'>
            <div className='question-text-container'>
                <div className='question-text'>
                <h1>{questionData.question}</h1>
                </div>
            </div>
            <ul className='main-quiz-answer-list'>
                {questionData.options.map((option, index) => (
                    <div className='option-button-container'>
                        <li key={option}>
                            <button
                                key={index}
                                onClick={() => handleOptionClick(option)}
                                className='option-button'
                            >
                                {option}
                            </button>
                        </li>
                    </div>
                ))}
            </ul>
            <div className="question-count-text">
                <p>{questionData.id}/10</p>
            </div>
        </div>
    );
};

export default Question;