
import './HomePage.css';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {

  const navigate = useNavigate();

  useEffect(() => {
  }, []);

  const leagues = [
    { id: '21', name: 'Premier League', image: '/Images/LeagueLogos/LRPremierLeagueLogoAsPNG.png' },
    { id: '22', name: 'La Liga', image: '/Images/LeagueLogos/LRLaLigaLogoAsPNG.png' },
    { id: '23', name: 'Ligue 1', image: '/Images/LeagueLogos/Ligue1Logo.png' },
    { id: '24', name: 'Seria A', image: '/Images/LeagueLogos/LRSeriaALogo.png' },
    { id: '25', name: 'Bundesliga', image: '/Images/LeagueLogos/LRBundesligaLogo.png' },
  ]

  const handleLeagueClick = (teamId) => {
    if (teamId) {
      if (teamId === 26) {
        navigate('/PremTeamQuizSelector');
      }
      else if (teamId < 26) {
        // Perform the necessary action on form submission
        navigate(`/quiz/${teamId}`);
      }
    } else {
      // Show an error message or perform other actions if necessary
      console.log('Please select both team and difficulty');
    }
  };

  const getClassName = (teamId) => {
    if (teamId === 16 || teamId === 18) {
      return 'non-standard-team-image';
    }
    else if (parseInt(teamId) === 21 || parseInt(teamId) === 24) {
      return 'non-standard-league-image'
    }
    else if (parseInt(teamId) === 26) {
      return 'prem-team-selector-image'
    }
    else {
      return 'standard-league-image'
    }
  }

  return (
    <div className='home-container'>
        <div class='leagues-title'>
          <img src={"/Images/HowWellDoYouKnowYourLeague.png"} alt='How well do you know you league' loading='lazy' />
        </div>
        <div className='league-buttons-container'>
          <div className="league-buttons">
            {leagues.map((league) => (
              <button
                key={league.id}
                className={`league-button`}
                onClick={() => handleLeagueClick(league.id)}
              >
                <img className={getClassName(league.id)} src={league.image} alt={league.name} loading='lazy' />
              </button>
            ))}
          </div>
        </div>

        <div>
          <div className='home-page-or-separator'>
            <img src={"/Images/OR.png"} alt='Home page or separator' loading='lazy' />
          </div>
          <div className='teams-title'>
            <img src={"/Images/HowWellDoYouKnowYourTeam.png"} alt='How well do you know your team' loading='lazy' />
          </div>

          <div className="team-selector-button">
            <button
              className={`prem-team-selector-button`}
              onClick={() => handleLeagueClick(26)}
            >
              <img className={getClassName(26)} src={"/Images/LRPremTeamsSelectorImage.png"} alt={"Premier League"} loading='lazy' />
            </button>

          </div>
        </div>
        <div className='home-page-info-text'>
          <p>The best football quizzes! With our European League's quiz and a Premier League quiz for any team, we have a fun football quiz for everyone!</p>
        </div>
    </div>
  );
};

export default HomePage;