import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationBar.css';

const NavigationBar = () => {
  return (
    <nav className="navigationBar">
      <div className="title">
        <Link to="/">
          <img src="/Images/SiteLogo.png" alt='Title page logo'/>
        </Link>
      </div>
      <ul>
        <li className='navBarLink'>
          <Link to="/">All Quizzes</Link>
        </li>
        <li className='navBarLink'>
          <Link to="/PremTeamQuizSelector">Premier League Quizzes</Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationBar;