import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Question from './Question';
import Summary from './Summary';
import './Question.css'

const questions = [
    //Arsenal
    [
      { id: 1, question: 'Who is the current Arsenal manager?', options: ['Mikel Arteta', 'Arsene Wenger', 'Sir Alex Ferguson', 'Thomas Tuchel'], correctAnswer: 'Mikel Arteta', image: '/Images/Arsenal/MikelArteta.jpg', gettyImage: '//embed.gettyimages.com/embed/1657386430?et=q3s0JD5NT1dmmd0eHdh38g&tld=com&sig=D-nVDxWBcxQYYgkTu66StqLLUrxNws9wW46YMQvsMHo=&caption=false&ver=1' },
      { id: 2, question: 'Who joined Arsenal in the summer of 2023 to become their most expensive signing ever?', options: ['Romelu Lukaku', 'Declan Rice', 'Neymar', 'Kevin De Bruyne'], correctAnswer: 'Declan Rice', image: '/Images/Arsenal/DeclanRice21.png', gettyImage: '//embed.gettyimages.com/embed/1659766922?et=Q6Hu4fjeTMFIONz2QoUwWA&tld=com&sig=ik7p3ki1FQPV88ddCH_Rabx4-LGJqEBm0njATVugy78=&caption=false&ver=1' },
      { id: 3, question: 'What is the nickname of Arsenal?', options: ['Potters', 'Blasters', 'London Devils', 'Gunners'], correctAnswer: 'Gunners', image: '/Images/Arsenal/Xhaka.jpg', gettyImage : '//embed.gettyimages.com/embed/1580959529?et=gyE4A83FQ01gNs0-9eEk9g&tld=com&sig=yJwq7IAyaIj4xElh8giExSnRWArBU8E2FsRqJBPbiYw=&caption=false&ver=1'},
      { id: 4, question: 'Which stadium is Arsenals home ground?', options: ['Old Trafford', 'Anfield', 'Etihad Stadium', 'Emirates Stadium'], correctAnswer: 'Emirates Stadium', image: '/Images/Arsenal/EmiratesStadiumLowerRes.jpg', gettyImage : '//embed.gettyimages.com/embed/1656999023?et=48VR3LGJQldaHrr8UgYlpQ&tld=com&sig=7ooyt2HFPk-dni6MC1dUu4o82WpIb-CxPkR9I5ghf-A=&caption=false&ver=1' },
      { id: 5, question: 'What club is considered Arsenals biggest rivals?', options: ['Chelsea', 'Tottenham Hotspur', 'Liverpool', 'Manchester City'], correctAnswer: 'Tottenham Hotspur', image: '/Images/Arsenal/ArsenalVsQuestion21.jpg', gettyImage : '//embed.gettyimages.com/embed/1657733084?et=ZBeqY0PpTfxqoxTRnA8qkA&tld=com&sig=veawrwvmq200jMIa_xrMVxCakTBd3WXKD-D55kvMsdk=&caption=false&ver=1' },
      { id: 6, question: 'Which French manager led Arsenal to three Premier League titles and seven FA Cup wins?', options: ['Zinedine Zidane', 'Didier Deschamps', 'Arsene Wenger', 'Laurent Blanc'], correctAnswer: 'Arsene Wenger', image: '/Images/Arsenal/ArseneWenger.jpg', gettyImage : '//embed.gettyimages.com/embed/1589289271?et=gf3N_USRRQlm0HD8BQ2dHQ&tld=com&sig=cBfbM14aPc7FYvFPVKuz0qB0FOXy5jxFCWFrHfnl3Ws=&caption=false&ver=1' },
      { id: 7, question: 'In which year did Arsenal complete an entire Premier League season unbeaten, earning the nickname "The Invincibles"?', options: ['1999-2000', '2003-2004', '2008-2009', '2013-2014'], correctAnswer: '2003-2004', image: '/Images/Arsenal/Invincibles.jpg', gettyImage : '//embed.gettyimages.com/embed/51219213?et=1QRed3f8TBFjGyf-odVlHQ&tld=com&sig=I5B_lTJvRcGvtpgjWCSfR6erFdCTrhJ3PTJJa2rNpzg=&caption=false&ver=1' },
      { id: 8, question: 'When did Arsenal win their last trophy, being an FA cup?', options: ['2015', '2010', '2005', '2020'], correctAnswer: '2020', image: '/Images/Arsenal/FaCup.jpg', gettyImage : '//embed.gettyimages.com/embed/1460096742?et=XvoHKKifTKZYzAmOLHuASQ&tld=com&sig=8Jf3fewW8M8TmLxP7eg4B2Gr0sCjmO9TfZ8oZWMT2i8=&caption=false&ver=1' },
      { id: 9, question: 'Arsenal won their last Premier League title in which season?', options: ['2001-2002', '2003-2004', '2006-2007', '2009-2010'], correctAnswer: '2003-2004', image: '/Images/Arsenal/Premier LeagueLowerRes.jpg', gettyImage : '' },
      { id: 10, question: 'Who is Arsenal\'s record sale, going to Liverpool for £35M?', options: ['Alexis Sanchez', 'Alex Oxlade-Chamberlain', 'Joe Willock', 'Thierry Henry'], correctAnswer: 'Alex Oxlade-Chamberlain', image: '/Images/Arsenal/ArsenalFans.jpg', gettyImage : '' }
    ],
    //Aston Villa
    [
      { id: 1, question: 'Which city is Aston Villa Football Club located in?', options: ['Manchester', 'Liverpool', 'Birmingham', 'London'], correctAnswer: 'Birmingham', image: '/Images/AstonVilla/BirminghamSkyline.jpeg' },
      { id: 2, question: 'In which season did Aston Villa return to the Premier League after winning the Championship Play-Off final?', options: ['2015-2016', '2016-2017', '2018-2019', '2019-2020'], correctAnswer: '2018-2019', image: '/Images/AstonVilla/Promotion.jpg' },
      { id: 3, question: 'Aston Villas record victory in the Premier League came in a 7-2 win against which team?', options: ['Manchester United', 'Chelsea', 'Liverpool', 'Arsenal'], correctAnswer: 'Liverpool', image: '/Images/AstonVilla/7-2Game.jpg' },
      { id: 4, question: 'Who is Aston Villas record signing, joining in the summer of 2023?', options: ['Jack Grealish', 'Moussa Diaby', 'Emiliano Buendia', 'Ollie Watkins'], correctAnswer: 'Moussa Diaby', image: '/Images/AstonVilla/DiabySigning.jpg' },
      { id: 5, question: 'What is Aston Villas nickname?', options: ['The Hammers', 'The Lions', 'The Villains', 'The Blues'], correctAnswer: 'The Lions', image: '/Images/AstonVilla/VillaFans.jpeg' },
      { id: 6, question: 'What is the capacity of Aston Villas home stadium?', options: ['22,000', '32,000', '42,000', '52,000'], correctAnswer: '42,000', image: '/Images/AstonVilla/PeterWithe.jpg' },
      { id: 7, question: 'Who is the record transfer sale for Aston Villa, leaving in the summer of 2021', options: ['Jack Grealish', 'James Milner', 'Christian Benteke', 'Dwight Yorke'], correctAnswer: 'Jack Grealish', image: '/Images/AstonVilla/JackGrealish.jpg' },
      { id: 8, question: 'What football ground is the home of Aston Villa?', options: ['St James Park', 'Villa Park', 'The Lions Den', 'Birmingham Stadium'], correctAnswer: 'Villa Park', image: '/Images/AstonVilla/VillaPark.jpg' },
      { id: 9, question: 'Which season did Aston Villa win their historic European Cup?', options: ['1973-74', '1981-82', '1989-90', '1997-98'], correctAnswer: '1981-82', image: '/Images/AstonVilla/RotterdamStadium.jpg' },
      { id: 10, question: 'Who is the current manager of Aston Villa?', options: ['Dean Smith', 'Steve Bruce', 'Unai Emery', 'Roy Keane'], correctAnswer: 'Unai Emery', image: '/Images/AstonVilla/UnaiEmery.jpg' }
    ],
    //AFC Bournemouth
    [
      { id: 1, question: 'Which team is considered Bournemouth\'s biggest rivals?', options: ['Portsmouth', 'Norwich', 'Fulham', 'Southampton'], correctAnswer: 'Southampton', image: '/Images/Bournemouth/OldBournemouthTeam (2).jpg' },
      { id: 2, question: 'Which stadium is AFC Bournemouths home ground?', options: ['Vitality Stadium', 'Dean Court', 'Vicarage Road', 'Liberty Stadium'], correctAnswer: 'Vitality Stadium', image: '/Images/Bournemouth/VitalityStadium (2).jpg' },
      { id: 3, question: 'Which manager guided AFC Bournemouth to their first-ever promotion to the Premier League in the 2014-2015 season?', options: ['Eddie Howe', 'Harry Redknapp', 'Chris Hughton', 'Steve Bruce'], correctAnswer: 'Eddie Howe', image: '/Images/Bournemouth/PromotionBournemouth (2).jpg' },
      { id: 4, question: 'What is the nickname of AFC Bournemouth?', options: ['The Hammers', 'The Cherries', 'The Villains', 'The Saints'], correctAnswer: 'The Cherries', image: '/Images/Bournemouth/BournemouthFans2Final.jpg' },
      { id: 5, question: 'In which season did AFC Bournemouth achieve their highest Premier League finish (9th place)?', options: ['2012-2013', '2014-2015', '2016-2017', '2018-2019'], correctAnswer: '2016-2017', image: '/Images/Bournemouth/Bournemouth2016-17Final.jpg' },
      { id: 6, question: 'Who is the record transfer signing for AFC Bournemouth?', options: ['Dominic Solanke', 'David Brooks', 'Jefferson Lerma', 'Nathan Aké'], correctAnswer: 'Jefferson Lerma', image: '/Images/Bournemouth/JeffersonLerma (2).jpg' },
      { id: 7, question: 'What is the capacity of AFC Bournemouths home ground, Vitality Stadium?', options: ['10,000', '15,000', '20,000', '25,000'], correctAnswer: '10,000', image: '/Images/Bournemouth/VitalityStadium2 (2).jpg' },
      { id: 8, question: 'Which striker, who later played for West Ham and Tottenham, began his professional career at Bournemouth?', options: ['Andy Carroll', 'Jermain Defoe', 'Michael Owen', 'Peter Crouch'], correctAnswer: 'Jermain Defoe', image: '/Images/Bournemouth/DefoeBournemouthFinal.jpg' },
      { id: 9, question: 'Bournemouth have seen a historic rise through the EFL, when were they last in League 2?', options: ['1994-95', '1999-00', '2004-05', '2009-10'], correctAnswer: '2009-10', image: '/Images/Bournemouth/BournemouthFansFinal.jpg' },
      { id: 10, question: 'Who is AFC Bournemouths record sale for a fee of £41M?', options: ['Steve Cook', 'Jefferson Lerma', 'Nathan Ake', 'Tyrone Mings'], correctAnswer: 'Nathan Ake', image: '/Images/Bournemouth/NathanAkeBournemouthFinal.jpg' }
    ],
    //Brentford
    [
      { id: 1, question: 'What is the name of Brentfords newly built stadium, which they moved to in the 2020-2021 season?', options: ['Griffin Park', 'Vicarage Road', 'The Hive', 'Brentford Community Stadium'], correctAnswer: 'Brentford Community Stadium', image: '/Images/Brentford/BrentfordStadium.jpg' },
      { id: 2, question: 'During the 2020-2021 Championship season, Brentford defeated which team to earn promotion in the Play-Off final?', options: ['Swansea City', 'Bournemouth', 'Barnsley', 'Norwich City'], correctAnswer: 'Swansea City', image: '/Images/Brentford/BrentfordPromotion.jpg' },
      { id: 3, question: 'Who is Brentfords most expensive ever sale, bringing in £28M?', options: ['Andre Gray', 'Ezri Konsa', 'Neal Maupay', 'Ollie Watkins'], correctAnswer: 'Ollie Watkins', image: '/Images/Brentford/OllieWatkins.jpg' },
      { id: 4, question: 'Ivan Toney matched the Championship goal scoring record in Brentfords promotion winning season with how many goals?', options: ['18', '30', '22', '26'], correctAnswer: '30', image: '/Images/Brentford/IvanToney.jpg' },
      { id: 5, question: 'Along with Fulham, Brentfords biggest rivalry is with which other nearby London club?', options: ['Chelsea', 'Queens Park Rangers', 'Charlton Athletic', 'Millwall'], correctAnswer: 'Queens Park Rangers', image: '/Images/Brentford/BrentfordVs2.png' },
      { id: 6, question: 'What is the capacity of Brentford Community Stadium?', options: ['10,000', '15,000', '20,000', '17,250'], correctAnswer: '17,250', image: '/Images/Brentford/BrentfordStadium2LowerRes.jpg' },
      { id: 7, question: 'Brentfords promotion in the 2020-2021 season marked their first-ever appearance in the top-flight league since which year?', options: ['1965', '1978', '2003', '1947'], correctAnswer: '1947', image: '/Images/Brentford/BrentfordPlayersLowerRes.jpg' },
      { id: 8, question: 'Who is the current Brenford Manager?', options: ['Thomas Frank', 'Uwe Roster', 'Dean Smith', 'Mark Warburton'], correctAnswer: 'Thomas Frank', image: '/Images/Brentford/ThomasFrank.jpg' },
      { id: 9, question: 'Brentford have had a huge rise through the Football League, which division did they win in during the the 2008-09 season?', options: ['National League', 'League 2', 'League 1', 'Championship'], correctAnswer: 'League 2', image: '/Images/Brentford/BrentfordMap (2).jpg' },
      { id: 10, question: 'Which Republic Of Ireland player became Brentfords most expensive signing ever in the summer of 2023-2024?', options: ['Nathan Collins', 'Aaron Hickey', 'Ivan Toney', 'Bryan Mbuemo'], correctAnswer: 'Nathan Collins', image: '/Images/Brentford/NathanCollins.jpg' }
    ],
    //Brighton
    [
      { id: 1, question: 'What is the name of Brighton & Hove Albions home stadium?', options: ['Vicarage Road', 'The Hawthorns', 'The Amex Stadium', 'Selhurst Park'], correctAnswer: 'The Amex Stadium', image: '/Images/Brighton/amex_stadium.jpg' },
      { id: 2, question: 'Brighton & Hove Albion achieved promotion to the Premier League for the first time in their history in which season?', options: ['2012-2013', '2015-2016', '2016-2017', '2017-2018'], correctAnswer: '2016-2017', image: '/Images/Brighton/BrightonPromotion.jpg' },
      { id: 3, question: 'What is the nickname of Brighton & Hove Albion?', options: ['The Seagulls', 'The Hornets', 'The Robins', 'The Lions'], correctAnswer: 'The Seagulls', image: '/Images/Brighton/BrightonFans.jpg' },
      { id: 4, question: 'Who is the current manager of Brighton & Hove Albion?', options: ['Eddie Howe', 'Oscar Garcia', 'Roy Hodgson', 'Roberto De Zerbi'], correctAnswer: 'Roberto De Zerbi', image: '/Images/Brighton/DeZerbi.jpg' },
      { id: 5, question: 'Brighton & Hove Albions record signing was made in the summer of 2023 from Watford. What is his name?', options: ['Adam Webster', 'Julio Enciso', 'Pervis Estupinan', 'Joao Pedro'], correctAnswer: 'Joao Pedro', image: '/Images/Brighton/JaoaPedro.jpg' },
      { id: 6, question: 'Who is the current chairman of Brighton & Hove Albion?', options: ['Tony Bloom', 'Chris Hughton', 'Graham Potter', 'Bruno Saltor'], correctAnswer: 'Tony Bloom', image: '/Images/Brighton/TonyBloom.jpg' },
      { id: 7, question: 'Who captained Brighton in the 2022-23 campaign to secure European Football for the first time in their history?', options: ['Lewis Dunk', 'Danny Welbeck', 'Alexis Mac Allister', 'Kaoru Mitoma'], correctAnswer: 'Lewis Dunk', image: '/Images/Brighton/LewisDunk.jpg' },
      { id: 8, question: 'Brighton & Hove Albions main rivalry is with which club, with whom they contest the M23 Derby?', options: ['Crystal Palace', 'Fulham', 'Southampton', 'West Bromwich Albion'], correctAnswer: 'Crystal Palace', image: '/Images/Brighton/BrightonVs2.png' },
      { id: 9, question: 'Which manager led Brighton & Hove Albion to their first-ever promotion to the top-flight league?', options: ['Graham Potter', 'Chris Hughton', 'Nigel Adkins', 'Slaven Bilić'], correctAnswer: 'Chris Hughton', image: '/Images/Brighton/ChrisHughton.png' },
      { id: 10, question: 'Brighton & Hove Albions record transfer sale was an Ecuadorian midfielder who joined Chelsea. What is his name?', options: ['Cesar Azpilicueta', 'Dani Alves', 'Marc Cucurella', 'Moises Caicedo'], correctAnswer: 'Moises Caicedo', image: '/Images/Brighton/Cucurella.jpg' }
    ],
    //Burnley
    [
      { id: 1, question: 'Who is the current Burnley manager, who got them promoted in the 2022-23 season', options: ['Sean Dyche', 'Eddie Howe', 'Vincent Kompany', 'Stan Ternent'], correctAnswer: 'Vincent Kompany', image: '/Images/Burnley/VincentKompany.jpg' },
      { id: 2, question: 'What is the nickname of Burnley?', options: ['The Lions', 'The Magpies', 'The Clarets', 'The Royals'], correctAnswer: 'The Clarets', image: '/Images/Burnley/BurnleyFans (2).jpg' },
      { id: 3, question: 'Which stadium is the home of Burnley?', options: ['Old Trafford', 'Anfield', 'Turf Moor', 'Emirates Stadium'], correctAnswer: 'Turf Moor', image: '/Images/Burnley/TurfMoor (2).jpg' },
      { id: 31, question: 'Which Burnley FC manager guided the team to the UEFA Europa League qualification for the 2018-2019 season?', options: ['Sean Dyche', 'Eddie Howe', 'Owen Coyle', 'Stan Ternent'], correctAnswer: 'Sean Dyche', image: '/Images/Burnley/SeanDyche.jpg' },
      { id: 5, question: 'What player is the all-time record signing for Burnley at £16.1 million?', options: ['Chris Wood', 'Matej Vydra', 'Robbie Brady', 'Zeki Amdouni'], correctAnswer: 'Zeki Amdouni', image: '/Images/Burnley/ZekiAmdouni.jpg' },
      { id: 6, question: 'Which club is considered Burnley\'s biggest rival?', options: ['Blackburn Rovers', 'Preston North End', 'Bolton Wanderers', 'Leeds United'], correctAnswer: 'Blackburn Rovers', image: '/Images/Burnley/BurnleyBlackburn (2).jpg' },
      { id: 7, question: 'What is the seating capacity of Burnley\'s home stadium?', options: ['18,000', '32,000', '22,000', '28,000'], correctAnswer: '22,000', image: '/Images/Burnley/BurnleyStadium (2).jpg' },
      { id: 8, question: 'Chris Wood is the record sale from Burnley, but for how much?', options: ['£25 million', '£20 million', '£15 million', '£10 million'], correctAnswer: '£25 million', image: '/Images/Burnley/ChrisWood (2).jpg' },
      { id: 9, question: 'Which player who was brought in on loan was Burnleys record goal scorer with 17 goals in their 2022-23 promotion campaign?', options: ['Chris Wood', 'Nathan Tella', 'Jack Cork', 'Connor Roberts'], correctAnswer: 'Nathan Tella', image: '/Images/Burnley/NathanTella (2).jpg' },
      { id: 10, question: 'Who is the 20 year old goalkeeper that got Burnley promoted in the 2022-23 season which led to a permanent move for £15M?', options: ['Ethan Horvath', 'James Trafford', 'Ben Foster', 'Ben Wilson'], correctAnswer: 'James Trafford', image: '/Images/Burnley/JamesTrafford (2).jpg' }
    ],
    //Chelsea
    [
      { id: 44, question: 'Which stadium is the home of Chelsea?', options: ['Old Trafford', 'Anfield', 'Stamford Bridge', 'Emirates Stadium'], correctAnswer: 'Stamford Bridge', image: '/Images/Chelsea/StamfordBridge.jpg' },
      { id: 46, question: 'Who is the current manager of Chelsea?', options: ['Frank Lampard', 'Mauricio Pochettino', 'Jose Mourinho', 'Antonio Conte'], correctAnswer: 'Mauricio Pochettino', image: '/Images/Chelsea/Pochettino.jpg' },
      { id: 49, question: 'What was the estimated transfer fee Chelsea paid to sign Eden Hazard from Lille in 2012?', options: ['£32 million', '£50 million', '£65 million', '£80 million'], correctAnswer: '£32 million', image: '/Images/Chelsea/Hazard1.jpg' },
      { id: 53, question: 'Which season did Chelsea win their first ever UEFA Champions League?', options: ['2004-05', '2008-09', '2011-12', '2014-15'], correctAnswer: '2011-12', image: '/Images/Chelsea/ChelseaUCL.jpg' },
      { id: 55, question: 'Which manager led Chelsea to their first Premier League title in the 2004-05 season?', options: ['José Mourinho', 'Carlo Ancelotti', 'Avram Grant', 'Luiz Felipe Scolari'], correctAnswer: 'José Mourinho', image: '/Images/Chelsea/ChelseaPremWinners.jpg' },
      { id: 70, question: 'Which Chelsea player holds the record for the most Premier League appearances for the club?', options: ['Frank Lampard', 'John Terry', 'Petr Čech', 'Didier Drogba'], correctAnswer: 'Frank Lampard', image: '/Images/Chelsea/ChelseaPlayers (2).jpg' },
      { id: 7, question: 'Who is Chelsea\'s record transfer costing them £106.8M?', options: ['Didier Drogba', 'Eden Hazard', 'Enzo Fernandez', 'Mykhailo Mudryk'], correctAnswer: 'Enzo Fernandez', image: '/Images/Chelsea/Enzo.jpg' },
      { id: 8, question: 'Who is the current owner of Chelsea?', options: ['Harry Hill', 'Todd Boehly', 'Roman Abramovich', 'Joel Glazer'], correctAnswer: 'Todd Boehly', image: '/Images/Chelsea/ToddBoely.jpg' },
      { id: 9, question: 'Who was the most expensive sale from Chelsea FC for an initial £88.3M', options: ['Eden Hazard', 'Frank Lampard', 'Juan Mata', 'Oscar'], correctAnswer: 'Eden Hazard', image: '/Images/Chelsea/ChelseaPlayers2.jpg' },
      { id: 10, question: 'Which season did Chelsea last win the Premier League?', options: ['1994-95', '2000-01', '2008-09', '2016-17'], correctAnswer: '2016-17', image: '/Images/Chelsea/ChelseaSkyline.jpg' }
    ],
    //Crystal Palace
    [
      { id: 1, question: 'What is the nickname of Crystal Palace?', options: ['The Reds', 'The Blues', 'The Eagles', 'The Owls'], correctAnswer: 'The Eagles', image: 'crystal_palace_image_3' },
      { id: 2, question: 'Which stadium is the home of Crystal Palace?', options: ['Old Trafford', 'Anfield', 'Selhurst Park', 'Emirates Stadium'], correctAnswer: 'Selhurst Park', image: '/Images/Crystal_Palace/SelhurstPark.jpg' },
      { id: 3, question: 'Who is the current manager of Crystal Palace?', options: ['Roy Hodgson', 'Frank de Boer', 'Sam Allardyce', 'Eddie Howe'], correctAnswer: 'Roy Hodgson', image: '/Images/Crystal_Palace/SelhurstPark.jpg' },
      { id: 4, question: 'Which legendary former Crystal Palace striker is the club\'s all-time leading goal scorer?', options: ['Wilfried Zaha', 'Mark Bright', 'Ian Wright', 'Andy Johnson'], correctAnswer: 'Ian Wright', image: 'crystal_palace_image_7' },
      { id: 5, question: 'Who was the most expensive departure from Crystal Palace for a fee of £50M?', options: ['Wilfried Zaha', 'Yannick Balasie', 'Christian Benteke', 'Aaron Wan-Bissaka'], correctAnswer: 'Aaron Wan-Bissaka', image: 'crystal_palace_image_9' },
      { id: 6, question: 'Who was the manager of Crystal Palace when they achieved promotion to the Premier League in 2013?', options: ['Ian Holloway', 'Tony Pulis', 'Sam Allardyce', 'Neil Warnock'], correctAnswer: 'Ian Holloway', image: 'crystal_palace_image_11' },
      { id: 7, question: 'Crystal Palace got to the 2016 FA Cup final, but lost 2-1 to which team?', options: ['Manchester City', 'Manchester United', 'Chelsea', 'Leicester'], correctAnswer: 'Manchester United', image: 'crystal_palace_image_13' },
      { id: 8, question: 'Which of these clubs is considered a rival of Crystal Palace?', options: ['Fulham', 'Brighton', 'Aston Villa', 'Watford'], correctAnswer: 'Brighton', image: 'crystal_palace_image_16' },
      { id: 9, question: 'What is the capacity Crystal Palace\'s stadium?', options: ['20,000', '25,000', '15,000', '30,000'], correctAnswer: '25,000', image: 'crystal_palace_image_19' },
      { id: 10, question: 'Which English midfielder joined Crystal Palace FC in 2020 and for a fee of £17M?', options: ['Wilfed Zaha', 'Cheick Doucoure', 'James Maddison', 'Eberechi Eze'], correctAnswer: 'Eberechi Eze', image: 'crystal_palace_image_20' }
    ],
    //Everton
    [
      { id: 1, question: 'What is the nickname of Everton?', options: ['The Reds', 'The Toffees', 'The Blues', 'The Gunners'], correctAnswer: 'The Toffees', image: 'everton_image_2' },
      { id: 2, question: 'Which stadium is the home of Everton?', options: ['Old Trafford', 'Anfield', 'Goodison Park', 'Emirates Stadium'], correctAnswer: 'Goodison Park', image: 'everton_image_4' },
      { id: 3, question: 'Who is the current manager of Everton?', options: ['Carlo Ancelotti', 'Sam Allardyce', 'David Moyes', 'Sean Dyche'], correctAnswer: 'Sean Dyche', image: 'everton_image_6' },
      { id: 4, question: 'Who is the record signing for Everton?', options: ['Gylfi Sigurdsson', 'Richarlison', 'Yannick Bolasie', 'Moise Kean'], correctAnswer: 'Gylfi Sigurdsson', image: 'everton_image_11' },
      { id: 5, question: 'Which English defender joined Everton from Manchester United in the summer of 2005 and later became the club captain?', options: ['Tom Cleverley', 'Phil Neville', 'Darron Gibson', 'Wayne Rooney'], correctAnswer: 'Phil Neville', image: 'everton_image_28' },
      { id: 6, question: 'In which year did Everton win their most recent English top-flight league title?', options: ['1986-1987', '1991-1992', '1994-1995', '2000-2001'], correctAnswer: '1986-1987', image: 'everton_image_13' },
      { id: 7, question: 'Who are Everton\'s bitter rivals, taking part in the Merseyside Derby?', options: ['Manchester United', 'Manchester City', 'Blackburn Rovers', 'Liverpool'], correctAnswer: 'Liverpool', image: 'everton_image_16' },
      { id: 8, question: 'What is the capacity of Everton\'s historic home stadium?', options: ['30,000', '40,000', '50,000', '60,000'], correctAnswer: '40,000', image: 'everton_image_19' },
      { id: 9, question: 'Which iconic English player, became the youngest ever Premier League goal scorer at Everton 5 days before his 17th birthday? ', options: ['Harry Kane', 'Wayne Rooney', 'Gareth Bale', 'Steven Gerrard'], correctAnswer: 'Wayne Rooney', image: '/Images/DeclanRice.jpg' },
      { id: 10, question: 'Who is Everton FCs record sale for £75M?', options: ['Romelu Lukaku', 'Idrissa Gueye', 'Wayne Rooney', 'Richarlison'], correctAnswer: 'Romelu Lukaku', image: '/Images/DeclanRice.jpg' }
    ],
    //Fulham
    [
      { id: 1, question: 'What is the nickname of Fulham?', options: ['The Reds', 'The Cottagers', 'The Blues', 'The Hammers'], correctAnswer: 'The Cottagers', image: 'fulham_image_2' },
      { id: 2, question: 'Which stadium is the home of Fulham?', options: ['Old Trafford', 'Anfield', 'Craven Cottage', 'Emirates Stadium'], correctAnswer: 'Craven Cottage', image: 'fulham_image_3' },
      { id: 3, question: 'Which team are the biggest rivals of Fulham?', options: ['Crystal Palace', 'Queen\'s Park Rangers', 'Aston Villa', 'Watford'], correctAnswer: 'Queen\'s Park Rangers', image: 'fulham_image_4' },
      { id: 4, question: 'In which year did Fulham FC reach the UEFA Europa League final?', options: ['2008', '2010', '2012', '2014'], correctAnswer: '2010', image: 'fulham_image_5' },
      { id: 5, question: 'Who is Fulhams record sale, leaving the club for a fee of £46M', options: ['Aleksandar Mitrovic', 'Clint Dempsey', 'Ryan Sessegnon', 'Chris Smalling'], correctAnswer: 'Aleksandar Mitrovic', image: '/Images/DeclanRice.jpg' },
      { id: 6, question: 'Who is the current manager of Fulham?', options: ['Scott Parker', 'Slaviša Jokanović', 'Marco Silva', 'Roy Hodgson'], correctAnswer: 'Marco Silva', image: 'fulham_image_6' },
      { id: 7, question: 'When did Fulham FC most recently win promotion to the Premier League, via the Championship play-offs?', options: ['2010', '2005', '2015', '2020'], correctAnswer: '2020', image: 'fulham_image_8' },
      { id: 8, question: 'What is the capacity of Fulhams home stadium?', options: ['20,000', '15,000', '25,000', '30,000'], correctAnswer: '25,000', image: '/Images/DeclanRice.jpg' },
      { id: 9, question: 'Aleksander Mitrovic became the player with the most goals in a championship season in 2021-22, with how many goals?', options: ['28', '33', '38', '43'], correctAnswer: '43', image: '/Images/DeclanRice.jpg' },
      { id: 10, question: 'Fulham signed a significant number of players after their promotion in 2018-19, how many players did they sign?', options: ['8', '12', '15', '20'], correctAnswer: '15', image: '/Images/DeclanRice.jpg' }
    ],
    //Liverpool
    [
      { id: 1, question: 'Which stadium is the home of Liverpool?', options: ['Old Trafford', 'Anfield', 'Goodison Park', 'Emirates Stadium'], correctAnswer: 'Anfield', image: 'liverpool_image_4' },
      { id: 2, question: 'Who is the current manager of Liverpool?', options: ['Jürgen Klopp', 'Brendan Rodgers', 'Roy Hodgson', 'Rafael Benítez'], correctAnswer: 'Jürgen Klopp', image: 'liverpool_image_6' },
      { id: 3, question: 'In which year did Liverpool FC win their most recent Premier League title?', options: ['2015-2016', '2017-2018', '2019-2020', '2020-2021'], correctAnswer: '2019-2020', image: 'liverpool_image_8' },
      { id: 4, question: 'Which current Liverpool FC player has won the golden boot on 3 occasions?', options: ['Mo Salah', 'Darwin Nunez', 'Luis Diaz', 'Diogo Jota'], correctAnswer: 'Mo Salah', image: 'liverpool_image_9' },
      { id: 5, question: 'What is the name of the club anthem of Liverpool?', options: ['You\'ll Never Walk Alone', 'Stand By Me', 'Livin\' on a Prayer', 'Don\'t Stop Believin\''], correctAnswer: 'You\'ll Never Walk Alone', image: 'liverpool_image_12' },
      { id: 6, question: 'In which year did Liverpool FC win their most recent UEFA Champions League title?', options: ['2004-2005', '2008-2009', '2012-2013', '2018-2019'], correctAnswer: '2018-2019', image: 'liverpool_image_15' },
      { id: 7, question: 'Who is Liverpool\'s record signing for a fee of £85M?', options: ['Sadio Mané', 'Mo Salah', 'Virgil van Dijk', 'Darwin Nunez'], correctAnswer: 'Darwin Nunez', image: 'liverpool_image_16' },
      { id: 8, question: 'Which team is known as the biggest rivals of Liverpool FC, taking part in the Merseyside Derby?', options: ['Manchester United', 'Manchester City', 'Leeds United', 'Everton FC'], correctAnswer: 'Everton FC', image: 'liverpool_image_16' },
      { id: 9, question: 'Who is Liverpool\'s record sale, leaving in the summer of 2017-2018?', options: ['Gareth Bale', 'Philippe Coutinho', 'Raheem Sterling', 'Naby Keïta'], correctAnswer: 'Philippe Coutinho', image: 'liverpool_image_16' },
      { id: 10, question: 'Which iconic Liverpool figure was the manager when they won their last Premier League title for almost 30 years in 1989-90?', options: ['Gerard Houllier', 'Bob Paisley', 'Kenny Dalglish', 'Steven Gerrard'], correctAnswer: 'Kenny Dalglish', image: 'liverpool_image_16' }
    ],
    //Luton
    [
      { id: 1, question: 'What is the nickname of Luton Town?', options: ['The Hatters', 'The Baggies', 'The Blues', 'The Cottagers'], correctAnswer: 'The Hatters', image: 'luton_image_2' },
      { id: 2, question: 'Which stadium is the home of Luton Town?', options: ['Old Trafford', 'Anfield', 'Kenilworth Road', 'Emirates Stadium'], correctAnswer: 'Kenilworth Road', image: 'luton_image_3' },
      { id: 3, question: 'Who is the current manager of Luton Town?', options: ['Nathan Jones', 'Graeme Jones', 'Rob Edwards', 'Gary Sweet'], correctAnswer: 'Rob Edwards', image: 'luton_image_6' },
      { id: 4, question: 'Which player is Lutons most expensive sale for a fee of £8M?', options: ['James Ward-Prowse', 'James Justin', 'Carlos Edwards', 'Matt Doherty'], correctAnswer: 'James Justin', image: '/Images/DeclanRice.jpg' },
      { id: 5, question: 'What is the capacity of Lutons home Stadium?', options: ['5,000', '10,000', '15,000', '20,000'], correctAnswer: '10,000', image: 'luton_image_8' },
      { id: 6, question: 'Who are Luton Town FCs biggest rivals?', options: ['Brighton', 'Fulham', 'Leicester', 'Watford'], correctAnswer: 'Watford', image: 'luton_image_10' },
      { id: 7, question: 'Which team did Luton defeat in the Championship play-off final to earn promotion to the Premier League for the first time?', options: ['Brentford', 'Sheffield Utd', 'Coventry City', 'Huddersfield Town'], correctAnswer: 'Coventry City', image: '/Images/DeclanRice.jpg' },
      { id: 8, question: 'Who is Luton FCs record signing upon their promotion to the Premier League for a fee of £5M', options: ['Adam Boyd', 'Ryan Giles', 'Ian Feuer', 'Carlton Morris'], correctAnswer: 'Ryan Giles', image: '/Images/DeclanRice.jpg' },
      { id: 9, question: 'Luton are known for their historic rise to the Premier League when were they last in the conference league?', options: ['2009-10', '2004-05', '2014-15', '2002-03'], correctAnswer: '2009-10', image: '/Images/DeclanRice.jpg' },
      { id: 10, question: 'Luton have 1 major trophy to their name which they achieved in 1987-88, in which competition?', options: ['Football League Cup', 'FA Cup', 'First Division', 'European Cup'], correctAnswer: 'Football League Cup', image: '/Images/DeclanRice.jpg' }
    ],
    //Man City
    [
      { id: 1, question: 'What is the nickname of Manchester City', options: ['The Reds', 'The Blues', 'The Citizens', 'The Sky Blues'], correctAnswer: 'The Citizens', image: 'mancity_image_2' },
      { id: 2, question: 'Which stadium is the home of Manchester City', options: ['Old Trafford', 'Anfield', 'Etihad Stadium', 'Emirates Stadium'], correctAnswer: 'Etihad Stadium', image: 'mancity_image_3' },
      { id: 3, question: 'Who is the all-time leading goal scorer for Manchester City?', options: ['Colin Bell', 'Sergio Agüero', 'David Silva', 'Yaya Touré'], correctAnswer: 'Sergio Agüero', image: 'mancity_image_4' },
      { id: 4, question: 'Who did Manchester City beat in the 2023 Champions League final to earn a historic treble?', options: ['Manchester United', 'Chelsea', 'Real Madrid', 'Inter Milan'], correctAnswer: 'Inter Milan', image: '/Images/DeclanRice.jpg' },
      { id: 5, question: 'Who is the current manager of Manchester City?', options: ['Pep Guardiola', 'José Mourinho', 'Ole Gunnar Solskjær', 'Jurgen Klopp'], correctAnswer: 'Pep Guardiola', image: 'mancity_image_6' },
      { id: 6, question: 'In which year did Manchester City win their first Premier League title?', options: ['2008-2009', '2011-2012', '2013-2014', '2017-2018'], correctAnswer: '2011-2012', image: 'mancity_image_8' },
      { id: 7, question: 'Who is Manchester Citys record signing for £100M?', options: ['Sergio Aguero', 'Jack Grealish', 'Rodri', 'Yaya Toure'], correctAnswer: 'Jack Grealish', image: '/Images/DeclanRice.jpg' },
      { id: 8, question: 'Erling Haaland became the holder of the record for the most goals in a Premier League season, with how many?', options: ['26', '31', '36', '41'], correctAnswer: '36', image: '/Images/DeclanRice.jpg' },
      { id: 9, question: 'What is the capacity of Manchester Citys home stadium?', options: ['43,000', '53,000', '63,000', '73,000'], correctAnswer: '53,000', image: '/Images/DeclanRice.jpg' },
      { id: 10, question: 'Who is Machester Citys most expensive sale for £47.5M?', options: ['Raheem Sterling', 'Shaun Wright-Phillips', 'Mario Balotelli', 'Leroy Sane'], correctAnswer: 'Raheem Sterling', image: '/Images/DeclanRice.jpg' }
    ],
    //Man Utd
    [
      { id: 1, question: 'What is the nickname of Manchester United?', options: ['The Reds', 'The Blues', 'The Devils', 'The Red Devils'], correctAnswer: 'The Red Devils', image: 'manutd_image_2' },
      { id: 2, question: 'Which stadium is the home of Manchester United?', options: ['Old Trafford', 'Anfield', 'Etihad Stadium', 'Emirates Stadium'], correctAnswer: 'Old Trafford', image: 'manutd_image_3' },
      { id: 3, question: 'Who is the all-time leading goal scorer for Manchester United?', options: ['George Best', 'Bobby Charlton', 'Wayne Rooney', 'Cristiano Ronaldo'], correctAnswer: 'Wayne Rooney', image: 'manutd_image_4' },
      { id: 4, question: 'Who is the current manager of Manchester United?', options: ['Ole Gunnar Solskjær', 'José Mourinho', 'Erik Ten Hag', 'Jürgen Klopp'], correctAnswer: 'Erik Ten Hag', image: 'manutd_image_6' },
      { id: 5, question: 'In which year did Manchester United win their first English Premier League title?', options: ['1992-93', '1994-95', '1996-97', '1998-99'], correctAnswer: '1992-93', image: 'manutd_image_8' },
      { id: 6, question: 'Who is the current captain of Manchester United?', options: ['Bruno Fernandes', 'Casemiro', 'Paul Pogba', 'Marcus Rashford'], correctAnswer: 'Bruno Fernandes', image: 'manutd_image_10' },
      { id: 7, question: 'Who is Manchester Uniteds record transfer, coming for a fee of £89M', options: ['Anthony', 'Casemiro', 'Marcus Rashford', 'Paul Pogba'], correctAnswer: 'Paul Pogba', image: '/Images/DeclanRice.jpg' },
      { id: 8, question: 'Who is Machester Uniteds record sale, leaving the club for a world record transfer fee at the time?', options: ['Romelu Lukaku', 'David Beckham', 'Christiano Ronaldo', 'Angel Di Maria'], correctAnswer: 'Christiano Ronaldo', image: '/Images/DeclanRice.jpg' },
      { id: 9, question: 'Which year did Machester United achieve a historic treble, winning the League, FA Cup and Champions League?', options: ['1998-99', '1992-93', '1995-96', '2001-02'], correctAnswer: '1998-99', image: '/Images/DeclanRice.jpg' },
      { id: 10, question: 'Who currently owns Manchester United?', options: ['The Glazers', 'The PIF of Saudi Arabia', 'Tony Bloom', 'Shahid Khan'], correctAnswer: 'The Glazers', image: '/Images/DeclanRice.jpg' }
    ],
    //Newcastle
    [
      { id: 1, question: 'What is the nickname of Newcastle United?', options: ['The Reds', 'The Blues', 'The Magpies', 'The Geordies'], correctAnswer: 'The Magpies', image: 'newcastle_image_2' },
      { id: 2, question: 'Which stadium is the home of Newcastle?', options: ['Stamford Bridge', 'Anfield', 'St. James\' Park', 'Emirates Stadium'], correctAnswer: 'St. James\' Park', image: 'newcastle_image_3' },
      { id: 3, question: 'Who is the all-time leading goal scorer for Newcastle United?', options: ['Alan Shearer', 'Malcolm Macdonald', 'Peter Beardsley', 'Les Ferdinand'], correctAnswer: 'Alan Shearer', image: 'newcastle_image_4' },
      { id: 4, question: 'Who is the current manager of Newcastle United?', options: ['Steve Bruce', 'Rafa Benítez', 'Alan Pardew', 'Eddie Howe'], correctAnswer: 'Eddie Howe', image: 'newcastle_image_6' },
      { id: 5, question: 'Who is the current most expensive player at Newcastle United FC, costing £63M?', options: ['Alan Shearer', 'Bruno Guimares', 'Harvey Barnes', 'Alexander Isak'], correctAnswer: 'Alexander Isak', image: '/Images/DeclanRice.jpg' },
      { id: 6, question: 'Who was the manager of Newcastle during their promotion from the championship in the 2016-17 season?', options: ['Rafael Benitez', 'Eddie Howe', 'Mick McCarthy', 'Dean Smith'], correctAnswer: 'Rafael Benitez', image: '/Images/DeclanRice.jpg' },
      { id: 7, question: 'Who is Newcastles most expensive sale, becoming the most expensive English player at the time?', options: ['Michael Owen', 'Andy Carroll', 'Raheem Sterling', 'James Milner'], correctAnswer: 'Andy Carroll', image: '/Images/DeclanRice.jpg' },
      { id: 8, question: 'Who are the current owners of Newcastle?', options: ['Tony Bloom', 'Mike Ashley', 'The Glazers', 'The PIF of Saudi Arabia'], correctAnswer: 'The PIF of Saudi Arabia', image: '/Images/DeclanRice.jpg' },
      { id: 9, question: 'what is the capacity of Newcastles home stadium?', options: ['42,000', '52,000', '62,000', '72,000'], correctAnswer: '52,000', image: '/Images/DeclanRice.jpg' },
      { id: 10, question: 'Which team are known to be Newcastle\'s biggest rivals?', options: ['Manchester United', 'Celtic', 'Blackburn Rovers', 'Sunderland'], correctAnswer: 'Sunderland', image: '/Images/DeclanRice.jpg' }
    ],
    //Forest
    [
      { id: 1, question: 'What is the nickname of Nottingham Forest ', options: ['The Bees', 'The Climbers', 'The Tricky Trees', 'The Sheriffs'], correctAnswer: 'The Tricky Trees', image: 'forest_image_2' },
      { id: 2, question: 'Which stadium is the home of Nottingham Forest?', options: ['City Ground', 'Anfield', 'Old Trafford', 'St. James\' Park'], correctAnswer: 'City Ground', image: 'forest_image_3' },
      { id: 3, question: 'What is the capacity of Nottingham Forest\'s home stadium?', options: ['30,000', '35,000', '40,000', '45,000'], correctAnswer: '30,000', image: '/Images/DeclanRice.jpg' },
      { id: 4, question: 'Who is the current manager of Nottingham Forest?', options: ['Sabri Lamouchi', 'Aitor Karanka', 'Nuno Espirito Santo', 'Martin O\'Neill'], correctAnswer: 'Nuno Espirito Santo', image: 'forest_image_6' },
      { id: 5, question: 'Which manager led the club from 13th in the second division to First Division titles and back-to-back European Cup victories?', options: ['Brian Clough', 'Peter Taylor', 'Bryan Robson', 'Terry Venables'], correctAnswer: 'Brian Clough', image: 'forest_image_7' },
      { id: 6, question: 'Who is the biggest rival of Nottingham Forest?', options: ['Watford', 'Leeds United', 'Derby County', 'Aston Villa'], correctAnswer: 'Derby County', image: '/Images/DeclanRice.jpg' },
      { id: 7, question: 'Who is the most expensive transfer at Nottingham Forest, costing £30M?', options: ['Dean Henderson', 'Brennan Johnson', 'Ibrahim Sangare', 'Morgan Gibbs-White'], correctAnswer: 'Ibrahim Sangare', image: '/Images/DeclanRice.jpg' },
      { id: 8, question: 'Who is the most expensive sale of Nottingham Forest, leaving for £47.5M in the 2023-24 season ?', options: ['Britt Assombalonga', 'Matty Cash', 'Michail Antonio', 'Brennan Johnson'], correctAnswer: 'Brennan Johnson', image: '/Images/DeclanRice.jpg' },
      { id: 9, question: 'Who joined Nottingham Forest in to become the first £1M player in 1979?', options: ['Teddy Sheringham', 'Trevor Francis', 'Colin Foster', 'Des Walker'], correctAnswer: 'Trevor Francis', image: '/Images/DeclanRice.jpg' },
      { id: 10, question: 'Which years did Nottingham Forest make history to win back-to-back European Cups?', options: ['1969 & 1970', '1979 & 1980', '1989 & 1990', '1999 & 2000'], correctAnswer: '1979 & 1980', image: '/Images/DeclanRice.jpg' }
    ],
    //Sheffield
    [
      { id: 1, question: 'What is the nickname of Sheffield United?', options: ['The Blades', 'The Reds', 'The Owls', 'The Bantams'], correctAnswer: 'The Blades', image: 'sheffieldunited_image_2' },
      { id: 2, question: 'Which stadium is the home of Sheffield United?', options: ['Bramall Lane', 'Anfield', 'Old Trafford', 'Hillsborough Stadium'], correctAnswer: 'Bramall Lane', image: 'sheffieldunited_image_3' },
      { id: 3, question: 'Who is the current manager of Sheffield United?', options: ['Chris Wilder', 'Neil Warnock', 'Paul Heckingbottom', 'Roy Hodgson'], correctAnswer: 'Chris Wilder', image: 'sheffieldunited_image_6' },
      { id: 4, question: 'What is the capacity of the home stadium of Sheffield United?', options: ['12,000', '22,000', '32,000', '42,000'], correctAnswer: '32,000', image: '/Images/DeclanRice.jpg' },
      { id: 5, question: 'What is the record transfer fee paid by Sheffield United for striker Rhian Brewster?', options: ['£40M', '£29.5M', '£23.5M', '£16.5M'], correctAnswer: '£23.5M', image: '/Images/DeclanRice.jpg' },
      { id: 6, question: 'Which manager took Sheffield United to their highest ever Premier League finish of 9th in the 2019-20 season?', options: ['Dean Smith', 'Neil Warnock', 'Eddie Howe', 'Chris Wilder'], correctAnswer: 'Chris Wilder', image: '/Images/DeclanRice.jpg' },
      { id: 7, question: 'How much was the record transfer fee recieved by Sheffield United when selling Aaron Ramsdale to Arsenal?', options: ['£15M', '£20M', '£25M', '£30M'], correctAnswer: '£25M', image: '/Images/DeclanRice.jpg' },
      { id: 8, question: 'Who are the biggest rivals of Sheffield United?', options: ['Manchester United', 'Bristol Rovers', 'Leicester', 'Sheffield Wednesday'], correctAnswer: 'Sheffield Wednesday', image: '/Images/DeclanRice.jpg' },
      { id: 9, question: 'Who was the manager to get Sheffield Promoted to the Premier League in the 2022-23 season?', options: ['Neil Warnock', 'Paul Heckingbottom', 'Roy Hodgson', 'Michael Carrick'], correctAnswer: 'Paul Heckingbottom', image: '/Images/DeclanRice.jpg' },
      { id: 10, question: 'Who is Sheffield United\'s top goal scorer, also having the record for the most Championship goals?', options: ['Billy Sharp', 'Lewis Grabban', 'Rhian Brewster', 'Clinton Morrison'], correctAnswer: 'Billy Sharp', image: '/Images/DeclanRice.jpg' }
    ],
    //Spurs
    [
      { id: 1, question: 'What is the historical alternative to Spurs as nickname of Tottenham Hotspur?', options: ['The Chickens', 'The Blue Birds', 'The Lilywhites', 'The Londoners'], correctAnswer: 'The Lilywhites', image: 'spurs_image_2' },
      { id: 2, question: 'Which stadium is the current home of Tottenham Hotspur?', options: ['Anfield', 'Old Trafford', 'Emirates Stadium', 'Tottenham Hotspur Stadium'], correctAnswer: 'Tottenham Hotspur Stadium', image: 'spurs_image_3' },
      { id: 3, question: 'Who is the current manager of Tottenham Hotspur?', options: ['José Mourinho', 'Mauricio Pochettino', 'Ange Postecoglou', 'Brendan Rodgers'], correctAnswer: 'Ange Postecoglou', image: 'spurs_image_6' },
      { id: 4, question: 'Which Welsh winger secured a move from Tottenham Hotspur to Real Madrid, for a world record transfer fee at the time?', options: ['Ryan Giggs', 'Gareth Bale', 'Aaron Ramsey', 'Harry Wilson'], correctAnswer: 'Gareth Bale', image: 'spurs_image_7' },
      { id: 5, question: 'The last trophy Tottenham Hotspur won was in the 2007-08 season, which trophy was it?', options: ['FA Cup', 'League Cup', 'Premier League', 'Champions League'], correctAnswer: 'League Cup', image: '/Images/DeclanRice.jpg' },
      { id: 6, question: 'What was Tottenham\'s old stadium called before moving to their curent home stadium in the 2019-20 season?', options: ['The Etihad Stadium', 'The City Ground', 'Hallam Street Stadium', 'White Hart Lane'], correctAnswer: 'White Hart Lane', image: '/Images/DeclanRice.jpg' },
      { id: 7, question: 'Who is the most expensive player bought by Tottenham Hotspur?', options: ['Gareth Bale', 'Harry Kane', 'Moussa Sissoko', 'Tanguy Ndombele'], correctAnswer: 'Tanguy Ndombele', image: '/Images/DeclanRice.jpg' },
      { id: 8, question: 'Who is Tottenham Hotspur\'s record goal scorer, coming through the academy and scoring 280 goals during his time at the club?', options: ['Romelu Lukaku', 'Gareth Bale', 'Harry Kane', 'Richarlison'], correctAnswer: 'Harry Kane', image: '/Images/DeclanRice.jpg' },
      { id: 9, question: 'What is the capacity of Totteham\'s home stadium?', options: ['53,000', '63,000', '73,000', '83,000'], correctAnswer: '63,000', image: '/Images/DeclanRice.jpg' },
      { id: 10, question: 'Who is Tottenham Hotspur\'s biggest rivals, taking part in the North London Derby?', options: ['Arsenal', 'Chelsea', 'Fulham', 'Watford'], correctAnswer: 'Arsenal', image: '/Images/DeclanRice.jpg' }
    ],
    //West Ham
    [
      { id: 1, question: 'What is the nickname of West Ham United?', options: ['The Hammers', 'The Irons', 'The Blades', 'The Bubbles'], correctAnswer: 'The Hammers', image: 'westham_image_2' },
      { id: 2, question: 'Which stadium is the home of West Ham United?', options: ['Stamford Bridge', 'The City Ground', 'Emirates Stadium', 'London Stadium'], correctAnswer: 'London Stadium', image: 'westham_image_3' },
      { id: 3, question: 'Who is the current manager of West Ham United?', options: ['Slaven Bilić', 'David Moyes', 'Manuel Pellegrini', 'Sam Allardyce'], correctAnswer: 'David Moyes', image: 'westham_image_6' },
      { id: 4, question: 'Which player scored in the 90\' minute of the 2022-23 UEFA Conference League final to secure the trophy for West Ham?', options: ['Sebastien Haller', 'Alphonse Areola', 'Tomas Soucek', 'Jarrod Bowen'], correctAnswer: 'Jarrod Bowen', image: '/Images/DeclanRice.jpg' },
      { id: 5, question: 'Who is the most expensive transfer of West Ham, arriving from Lyon and costing £51M', options: ['Kurt Zouma', 'Lucas Paqueta', 'Gianluca Scamacca', 'Declan Rice'], correctAnswer: 'Lucas Paqueta', image: '/Images/DeclanRice.jpg' },
      { id: 6, question: 'What is the capactity of West Ham\'s Stadium, previously used for the 2012 Olympics?', options: ['32,500', '42,500', '52,500', '62,500'], correctAnswer: '62,500', image: '/Images/DeclanRice.jpg' },
      { id: 7, question: 'Who are West Ham\'s biggest rivals, with the derby gaining notoriety for its frequent hooliganism?', options: ['Fulham', 'Chelsea', 'Millwall', 'Watford'], correctAnswer: 'Millwall', image: '/Images/DeclanRice.jpg' },
      { id: 8, question: 'Who is West Ham\'s biggest sale, earning them a record fee for an English player?', options: ['Declan Rice', 'Jarrod Bowen', 'James Ward-Prowse', 'Rio Ferdiand'], correctAnswer: 'Declan Rice', image: '/Images/DeclanRice.jpg' },
      { id: 9, question: 'Which season were West Ham last in the Championship, earning promotion through the playoffs?', options: ['2003-04', '2007-08', '2011-12', '2015-16'], correctAnswer: '2011-12', image: '/Images/DeclanRice.jpg' },
      { id: 10, question: 'Which season did West Ham win their last major trophy, being an FA Cup?', options: ['1979-80', '1989-90', '1999-00', '2009-10'], correctAnswer: '1979-80', image: '/Images/DeclanRice.jpg' }
    ],
    //Wolves
    [
      { id: 1, question: 'What is the nickname of Wolverhampton Wanderers?', options: ['The Roarers', 'Yellow Devils', 'Wolves', 'Barkers'], correctAnswer: 'Wolves', image: 'wolves_image_2' },
      { id: 2, question: 'Which stadium is the home of Wolverhampton Wanderers?', options: ['Molineux Stadium', 'Anfield', 'Old Trafford', 'Villa Park'], correctAnswer: 'Molineux Stadium', image: 'wolves_image_3' },
      { id: 3, question: 'Who is the current manager of Wolverhampton Wanderers?', options: ['Nuno Espírito Santo', 'Roy Hodgson', 'Gary O\'Neil', 'Mauricio Pochettino'], correctAnswer: 'Gary O\'Neil', image: 'wolves_image_6' },
      { id: 4, question: 'In which season did Wolverhampton Wanderers win their most recent major trophy, a League Cup?', options: ['1969-70', '1979-80', '1989-90', '1999-00'], correctAnswer: '1979-80', image: 'wolves_image_8' },
      { id: 5, question: 'Who is the most expensive transfer of Wolverhamton, arriving from Athletico Madrid for £44M', options: ['Raul Jimenez', 'Matheus Cunha', 'Pedro Neto', 'Ruben Neves'], correctAnswer: 'Matheus Cunha', image: '/Images/DeclanRice.jpg' },
      { id: 6, question: 'What is the capacity of the Wolverhamton Wanderers Stadium?', options: ['22,000', '32,000', '42,000', '52,000'], correctAnswer: '32,000', image: '/Images/DeclanRice.jpg' },
      { id: 7, question: 'Who is the most expensive sale from Wolverhampton, leaving to go to Man City for £53M?', options: ['Ruben Neves', 'Raul Jimenez', 'Diogo Jota', 'Matheus Nunes'], correctAnswer: 'Matheus Nunes', image: '/Images/DeclanRice.jpg' },
      { id: 8, question: 'Who are the biggest rivals of Wolverhampton Wanderers?', options: ['West Bromich Albion', 'Nottingham Forest', 'Preston North End', 'Watford'], correctAnswer: 'West Bromich Albion', image: '/Images/DeclanRice.jpg' },
      { id: 9, question: 'When did Wolverhampton last get promoted, winning the Championship with Nuno Espirito Santo as manager?', options: ['2007-08', '2012-13', '2017-18', '2022-23'], correctAnswer: '2017-18', image: '/Images/DeclanRice.jpg' },
      { id: 10, question: 'Wolverhampton achieved a record point tally in League 1 in the 2013-14 season, how many points did they get?', options: ['83', '93', '103', '113'], correctAnswer: '103', image: '/Images/DeclanRice.jpg' }
    ],
    //Premier League
    [
      { id: 1, question: 'Which player has the most Premier League appearances?', options: ['Wayne Rooney', 'Gareth Barry', 'Yaya Toure', 'Alan Shearer'], correctAnswer: 'Gareth Barry', image: '/Images/Arsenal/MikelArteta.jpg', gettyImage: '//embed.gettyimages.com/embed/1657386430?et=q3s0JD5NT1dmmd0eHdh38g&tld=com&sig=D-nVDxWBcxQYYgkTu66StqLLUrxNws9wW46YMQvsMHo=&caption=false&ver=1' },
      { id: 2, question: 'Which team has won the most Premier League titles?', options: ['Manchester United', 'Liverpool', 'Newcastle', 'Manchester City'], correctAnswer: 'Manchester United', image: '/Images/Arsenal/DeclanRice21.png', gettyImage: '//embed.gettyimages.com/embed/1659766922?et=Q6Hu4fjeTMFIONz2QoUwWA&tld=com&sig=ik7p3ki1FQPV88ddCH_Rabx4-LGJqEBm0njATVugy78=&caption=false&ver=1' },
      { id: 3, question: 'Which year was the Premier League founded, being rebranded from the First Division?', options: ['1982', '1987', '1992', '1997'], correctAnswer: '1992', image: '/Images/Arsenal/Xhaka.jpg', gettyImage : '//embed.gettyimages.com/embed/1580959529?et=gyE4A83FQ01gNs0-9eEk9g&tld=com&sig=yJwq7IAyaIj4xElh8giExSnRWArBU8E2FsRqJBPbiYw=&caption=false&ver=1'},
      { id: 4, question: 'Who is the Premier League\'s top goalscorer?', options: ['Wayne Rooney', 'Gary Lineker', 'Mohamed Salah', 'Alan Shearer'], correctAnswer: 'Alan Shearer', image: '/Images/Arsenal/EmiratesStadiumLowerRes.jpg', gettyImage : '//embed.gettyimages.com/embed/1656999023?et=48VR3LGJQldaHrr8UgYlpQ&tld=com&sig=7ooyt2HFPk-dni6MC1dUu4o82WpIb-CxPkR9I5ghf-A=&caption=false&ver=1' },
      { id: 5, question: 'Which of these teams were NOT in the first ever Premier Season?', options: ['Everton', 'Manchester City', 'Middlesbrough', 'Newcastle'], correctAnswer: 'Newcastle', image: '/Images/Arsenal/ArsenalVsQuestion21.jpg', gettyImage : '//embed.gettyimages.com/embed/1657733084?et=ZBeqY0PpTfxqoxTRnA8qkA&tld=com&sig=veawrwvmq200jMIa_xrMVxCakTBd3WXKD-D55kvMsdk=&caption=false&ver=1' },
      { id: 6, question: 'Which manager has the most Premier League titles?', options: ['Pep Guardiola', 'Jose Mourinho', 'Arsene Wenger', 'Sir Alex Ferguson'], correctAnswer: 'Sir Alex Ferguson', image: '/Images/Arsenal/ArseneWenger.jpg', gettyImage : '//embed.gettyimages.com/embed/1589289271?et=gf3N_USRRQlm0HD8BQ2dHQ&tld=com&sig=cBfbM14aPc7FYvFPVKuz0qB0FOXy5jxFCWFrHfnl3Ws=&caption=false&ver=1' },
      { id: 7, question: 'Manchester City achieved the most points in a Premier League season with how many?', options: ['90', '95', '100', '105'], correctAnswer: '100', image: '/Images/Arsenal/Invincibles.jpg', gettyImage : '//embed.gettyimages.com/embed/51219213?et=1QRed3f8TBFjGyf-odVlHQ&tld=com&sig=I5B_lTJvRcGvtpgjWCSfR6erFdCTrhJ3PTJJa2rNpzg=&caption=false&ver=1' },
      { id: 8, question: 'Which team has the lowest Premier League points total in a season, with only 11 points?', options: ['Leicester City', 'Crystal Palace', 'Derby County', 'Ipswich'], correctAnswer: 'Derby County', image: '/Images/Arsenal/FaCup.jpg', gettyImage : '//embed.gettyimages.com/embed/1460096742?et=XvoHKKifTKZYzAmOLHuASQ&tld=com&sig=8Jf3fewW8M8TmLxP7eg4B2Gr0sCjmO9TfZ8oZWMT2i8=&caption=false&ver=1' },
      { id: 9, question: 'Which stadium is the largest in the Premier League?', options: ['Old Trafford', 'Anfield', 'Stamford Bridge', 'The Etihad'], correctAnswer: 'Old Trafford', image: '/Images/Arsenal/Premier LeagueLowerRes.jpg', gettyImage : '' },
      { id: 10, question: 'Which player has the record for the most goals in a single Premier League season, with 35 goals?', options: ['Harry Kane', 'Erling Haaland', 'Wayne Rooney', 'Gareth Bale'], correctAnswer: 'Erling Haaland', image: '/Images/Arsenal/ArsenalFans.jpg', gettyImage : '' }
    ],
    //La Liga
    [
      { id: 1, question: 'Which club holds the record for the most La Liga titles?', options: ['Real Madrid', 'Barcelona', 'Atletico Madrid', 'Athletic Bilbao'], correctAnswer: 'Real Madrid', image: '/Images/Arsenal/MikelArteta.jpg', gettyImage: '//embed.gettyimages.com/embed/1657386430?et=q3s0JD5NT1dmmd0eHdh38g&tld=com&sig=D-nVDxWBcxQYYgkTu66StqLLUrxNws9wW46YMQvsMHo=&caption=false&ver=1' },
      { id: 2, question: 'Who is the all-time top scorer in the history of La Liga?', options: ['Telmo Zarra', 'Cristiano Ronaldo', 'Lionel Messi', 'Hugo Sanchez'], correctAnswer: 'Lionel Messi', image: '/Images/Arsenal/DeclanRice21.png', gettyImage: '//embed.gettyimages.com/embed/1659766922?et=Q6Hu4fjeTMFIONz2QoUwWA&tld=com&sig=ik7p3ki1FQPV88ddCH_Rabx4-LGJqEBm0njATVugy78=&caption=false&ver=1' },
      { id: 3, question: 'Which stadium has the largest seating capacity in La Liga?', options: ['Camp Nou', 'Santiago Bernabeu', ' Wanda Metropolitano', 'Mestalla'], correctAnswer: 'Camp Nou', image: '/Images/Arsenal/Xhaka.jpg', gettyImage : '//embed.gettyimages.com/embed/1580959529?et=gyE4A83FQ01gNs0-9eEk9g&tld=com&sig=yJwq7IAyaIj4xElh8giExSnRWArBU8E2FsRqJBPbiYw=&caption=false&ver=1'},
      { id: 4, question: 'Who is the manager with the most La Liga titles?', options: ['Diego Simeone', ' Pep Guardiola', 'Luis Enrique', 'Miguel Munoz'], correctAnswer: 'Miguel Munoz', image: '/Images/Arsenal/EmiratesStadiumLowerRes.jpg', gettyImage : '//embed.gettyimages.com/embed/1656999023?et=48VR3LGJQldaHrr8UgYlpQ&tld=com&sig=7ooyt2HFPk-dni6MC1dUu4o82WpIb-CxPkR9I5ghf-A=&caption=false&ver=1' },
      { id: 5, question: 'Which player holds the (joint) record for the most appearances in La Liga?', options: ['Andoni Zubizarreta', 'Xavi Hernandez', 'Iker Casillas', 'Raul Gonzalez'], correctAnswer: 'Andoni Zubizarreta', image: '/Images/Arsenal/ArsenalVsQuestion21.jpg', gettyImage : '//embed.gettyimages.com/embed/1657733084?et=ZBeqY0PpTfxqoxTRnA8qkA&tld=com&sig=veawrwvmq200jMIa_xrMVxCakTBd3WXKD-D55kvMsdk=&caption=false&ver=1' },
      { id: 6, question: 'Which club has the nickname "Los Colchoneros" in La Liga?', options: ['Sevilla', 'Valencia', 'Atletico Madrid', 'Real Sociedad'], correctAnswer: 'Atletico Madrid', image: '/Images/Arsenal/ArseneWenger.jpg', gettyImage : '//embed.gettyimages.com/embed/1589289271?et=gf3N_USRRQlm0HD8BQ2dHQ&tld=com&sig=cBfbM14aPc7FYvFPVKuz0qB0FOXy5jxFCWFrHfnl3Ws=&caption=false&ver=1' },
      { id: 7, question: 'Who is the only player to have scored in every minute of a La Liga match?', options: ['Lionel Messi', 'Cristiano Ronaldo', 'Gareth Bale', 'Luis Suarez'], correctAnswer: 'Cristiano Ronaldo', image: '/Images/Arsenal/Invincibles.jpg', gettyImage : '//embed.gettyimages.com/embed/51219213?et=1QRed3f8TBFjGyf-odVlHQ&tld=com&sig=I5B_lTJvRcGvtpgjWCSfR6erFdCTrhJ3PTJJa2rNpzg=&caption=false&ver=1' },
      { id: 8, question: 'Which player has won the most Pichichi Trophies (top scorer award) in La Liga history?', options: ['Lionel Messi', 'Cristiano Ronaldo', 'Telmo Zarra', 'Hugo Sanchez'], correctAnswer: 'Lionel Messi', image: '/Images/Arsenal/FaCup.jpg', gettyImage : '//embed.gettyimages.com/embed/1460096742?et=XvoHKKifTKZYzAmOLHuASQ&tld=com&sig=8Jf3fewW8M8TmLxP7eg4B2Gr0sCjmO9TfZ8oZWMT2i8=&caption=false&ver=1' },
      { id: 9, question: 'Who is the youngest player to have ever made an appearance in a La Liga match?', options: ['Ansu Fati', 'Lamine Yamal', 'Lionel Messi', 'Pedri'], correctAnswer: 'Lamine Yamal', image: '/Images/Arsenal/Premier LeagueLowerRes.jpg', gettyImage : '' },
      { id: 10, question: 'Which of these teams has NOT won La Liga', options: ['Sevilla', 'Mallorca', 'Valencia', 'Real Betis'], correctAnswer: 'Mallorca', image: '/Images/Arsenal/ArsenalFans.jpg', gettyImage : '' }
    ],
    //Ligue 1
    [
      { id: 1, question: 'Which team has won the most Ligue 1 titles?', options: ['Olympique Lyonnais', 'OGC Nice', 'Olympique de Marseille', 'Paris Saint-German'], correctAnswer: 'Paris Saint-German', image: '/Images/Arsenal/MikelArteta.jpg', gettyImage: '//embed.gettyimages.com/embed/1657386430?et=q3s0JD5NT1dmmd0eHdh38g&tld=com&sig=D-nVDxWBcxQYYgkTu66StqLLUrxNws9wW46YMQvsMHo=&caption=false&ver=1' },
      { id: 2, question: 'Which player holds the all time record for the most Ligue 1 goals?', options: ['Kylian Mbappe', 'Olivier Giroud', 'Delio Onnis', 'Wissam Ben Yedder'], correctAnswer: 'Delio Onnis', image: '/Images/Arsenal/DeclanRice21.png', gettyImage: '//embed.gettyimages.com/embed/1659766922?et=Q6Hu4fjeTMFIONz2QoUwWA&tld=com&sig=ik7p3ki1FQPV88ddCH_Rabx4-LGJqEBm0njATVugy78=&caption=false&ver=1' },
      { id: 3, question: 'Which player has the most Ligue 1 appearances?', options: ['Olivier Giroud', 'Laurent Blanc', 'Karim Benzema', 'Michael Landreau'], correctAnswer: 'Michael Landreau', image: '/Images/Arsenal/Xhaka.jpg', gettyImage : '//embed.gettyimages.com/embed/1580959529?et=gyE4A83FQ01gNs0-9eEk9g&tld=com&sig=yJwq7IAyaIj4xElh8giExSnRWArBU8E2FsRqJBPbiYw=&caption=false&ver=1'},
      { id: 4, question: 'Which team is considered Paris Saint-German\'s biggest rivals. taking part in Le Classique?', options: ['Olympique Lyonnais', 'OGC Nice', 'FC Nantes', 'Olympique de Marseille'], correctAnswer: 'Olympique de Marseille', image: '/Images/Arsenal/EmiratesStadiumLowerRes.jpg', gettyImage : '//embed.gettyimages.com/embed/1656999023?et=48VR3LGJQldaHrr8UgYlpQ&tld=com&sig=7ooyt2HFPk-dni6MC1dUu4o82WpIb-CxPkR9I5ghf-A=&caption=false&ver=1' },
      { id: 5, question: 'Which manager has won the most Ligue 1 titles?', options: ['Albert Batteux', 'Thomas Tuchel', 'Didier Deschamps', 'Gerard Houllier'], correctAnswer: 'Albert Batteux', image: '/Images/Arsenal/ArsenalVsQuestion21.jpg', gettyImage : '//embed.gettyimages.com/embed/1657733084?et=ZBeqY0PpTfxqoxTRnA8qkA&tld=com&sig=veawrwvmq200jMIa_xrMVxCakTBd3WXKD-D55kvMsdk=&caption=false&ver=1' },
      { id: 6, question: 'Who was the most expensive Ligue 1 transfer, moving for £200 million?', options: ['Zinedine Zidane', 'Neymar', 'Kylian Mbappe', 'Paul Pogba'], correctAnswer: 'Neymar', image: '/Images/Arsenal/ArseneWenger.jpg', gettyImage : '//embed.gettyimages.com/embed/1589289271?et=gf3N_USRRQlm0HD8BQ2dHQ&tld=com&sig=cBfbM14aPc7FYvFPVKuz0qB0FOXy5jxFCWFrHfnl3Ws=&caption=false&ver=1' },
      { id: 7, question: 'Which team is the only Ligue 1 team to have won the UEFA Champions League?', options: ['Paris Saint-German', 'Olympique Lyonnais', 'Olympique de Marseille', 'AS Monaco'], correctAnswer: 'Olympique de Marseille', image: '/Images/Arsenal/Invincibles.jpg', gettyImage : '//embed.gettyimages.com/embed/51219213?et=1QRed3f8TBFjGyf-odVlHQ&tld=com&sig=I5B_lTJvRcGvtpgjWCSfR6erFdCTrhJ3PTJJa2rNpzg=&caption=false&ver=1' },
      { id: 8, question: 'Which Ligue 1 team has the largest capacity stadium?', options: ['OGC Nice', 'AS Monaco', 'Olympique de Marseille', 'Paris Saint-German'], correctAnswer: 'Olympique de Marseille', image: '/Images/Arsenal/FaCup.jpg', gettyImage : '//embed.gettyimages.com/embed/1460096742?et=XvoHKKifTKZYzAmOLHuASQ&tld=com&sig=8Jf3fewW8M8TmLxP7eg4B2Gr0sCjmO9TfZ8oZWMT2i8=&caption=false&ver=1' },
      { id: 9, question: 'Which player is the youngest player to ever play in Ligue 1, coming on for LOSC Lille?', options: ['Warren Zaire-Emery', 'Antoinne Griezmann', 'Kylian Mbappe', 'Ayyoub Bouaddi'], correctAnswer: 'Ayyoub Bouaddi', image: '/Images/Arsenal/Premier LeagueLowerRes.jpg', gettyImage : '' },
      { id: 10, question: 'Which of these teams has NOT won Ligue 1?', options: ['OGC Nice', 'LOSC Lille', 'Stade de Reims', 'Stade Rennais FC'], correctAnswer: 'Stade Rennais FC', image: '/Images/Arsenal/ArsenalFans.jpg', gettyImage : '' }
    ],
    //Seria A
    [
      { id: 1, question: "Which club has the most Seria A titles?",options: ["AC Milan", "Juventus", "AS Roma", "Inter Milan"],correctAnswer: "Juventus", image: '/Images/Arsenal/MikelArteta.jpg', gettyImage: '//embed.gettyimages.com/embed/1657386430?et=q3s0JD5NT1dmmd0eHdh38g&tld=com&sig=D-nVDxWBcxQYYgkTu66StqLLUrxNws9wW46YMQvsMHo=&caption=false&ver=1' },
      { id: 2, question: 'Who has the record as all time top goal scorer in Seria A ?', options: ['Romelu Lukaku', 'Silvio Piola', 'Zlatan Ibrahimovic', 'Francesco Totti'], correctAnswer: 'Silvio Piola', image: '/Images/Arsenal/DeclanRice21.png', gettyImage: '//embed.gettyimages.com/embed/1659766922?et=Q6Hu4fjeTMFIONz2QoUwWA&tld=com&sig=ik7p3ki1FQPV88ddCH_Rabx4-LGJqEBm0njATVugy78=&caption=false&ver=1' },
      { id: 3, question: 'Which legendary player has the most Seria A appearances?', options: ['Gianluigi Buffon', 'Lautaro Martinez', 'Gianni Rivera', 'Andrea Pirlo'], correctAnswer: 'Gianluigi Buffon', image: '/Images/Arsenal/Xhaka.jpg', gettyImage : '//embed.gettyimages.com/embed/1580959529?et=gyE4A83FQ01gNs0-9eEk9g&tld=com&sig=yJwq7IAyaIj4xElh8giExSnRWArBU8E2FsRqJBPbiYw=&caption=false&ver=1'},
      { id: 4, question: 'Who is the youngest player ever to play in Seria, coming on for AC Milan?', options: ['Andreas Pirlo', 'Paulo Maldini', 'Francesco Camarda', 'Amad Diallo'], correctAnswer: 'Francesco Camarda', image: '/Images/Arsenal/EmiratesStadiumLowerRes.jpg', gettyImage : '//embed.gettyimages.com/embed/1656999023?et=48VR3LGJQldaHrr8UgYlpQ&tld=com&sig=7ooyt2HFPk-dni6MC1dUu4o82WpIb-CxPkR9I5ghf-A=&caption=false&ver=1' },
      { id: 5, question: 'What Seria A club is considered Roma FC \'s biggest rivals?', options: ['Juventus', 'Atalanta', 'Sampdoria', 'Lazio'], correctAnswer: 'Lazio', image: '/Images/Arsenal/ArsenalVsQuestion21.jpg', gettyImage : '//embed.gettyimages.com/embed/1657733084?et=ZBeqY0PpTfxqoxTRnA8qkA&tld=com&sig=veawrwvmq200jMIa_xrMVxCakTBd3WXKD-D55kvMsdk=&caption=false&ver=1' },
      { id: 6, question: 'Which manager has won the most Seria A titles?', options: ['Sven-Goran Eriksson', 'Jose Mourinho', 'Giovanni Trapattoni', 'Pep Guardiola'], correctAnswer: 'Giovanni Trapattoni', image: '/Images/Arsenal/ArseneWenger.jpg', gettyImage : '//embed.gettyimages.com/embed/1589289271?et=gf3N_USRRQlm0HD8BQ2dHQ&tld=com&sig=cBfbM14aPc7FYvFPVKuz0qB0FOXy5jxFCWFrHfnl3Ws=&caption=false&ver=1' },
      { id: 7, question: 'Who is the most expensive transfer in Seria A history, joining Juventus for £105 million?', options: ['Matthijs De Ligt', 'Mohammed Salah', 'Christiano Ronaldo', 'Sergej Milinković-Savić'], correctAnswer: 'Christiano Ronaldo', image: '/Images/Arsenal/Invincibles.jpg', gettyImage : '//embed.gettyimages.com/embed/51219213?et=1QRed3f8TBFjGyf-odVlHQ&tld=com&sig=I5B_lTJvRcGvtpgjWCSfR6erFdCTrhJ3PTJJa2rNpzg=&caption=false&ver=1' },
      { id: 8, question: 'Which Seria A team has the highest capacity stadium?', options: ['Juventus', 'Napoli', 'ACF Fiorentina', 'AC Milan'], correctAnswer: 'AC Milan', image: '/Images/Arsenal/FaCup.jpg', gettyImage : '//embed.gettyimages.com/embed/1460096742?et=XvoHKKifTKZYzAmOLHuASQ&tld=com&sig=8Jf3fewW8M8TmLxP7eg4B2Gr0sCjmO9TfZ8oZWMT2i8=&caption=false&ver=1' },
      { id: 9, question: 'Which Serie A club has the most UEFA Champions League titles?', options: ['AC Milan', 'Inter Milan', 'Juventus', 'Napoli'], correctAnswer: 'AC Milan', image: '/Images/Arsenal/Premier LeagueLowerRes.jpg', gettyImage : '' },
      { id: 10, question: 'Which of these teams has NOT won Seria A?', options: ['Roma', 'Torino', 'Genoa', 'Atalanta'], correctAnswer: 'Atalanta', image: '/Images/Arsenal/ArsenalFans.jpg', gettyImage : '' }
    ],
    //Bundesliga
    [
      { id: 1, question: 'Which team has won the most Bundesliga titles?', options: [ 'Borussia Dortmund', 'Bayern Munich', 'Bayer Leverkusen', 'Hoffenheim'], correctAnswer: 'Bayern Munich', image: '/Images/Arsenal/MikelArteta.jpg', gettyImage: '//embed.gettyimages.com/embed/1657386430?et=q3s0JD5NT1dmmd0eHdh38g&tld=com&sig=D-nVDxWBcxQYYgkTu66StqLLUrxNws9wW46YMQvsMHo=&caption=false&ver=1' },
      { id: 2, question: 'Which player holds the all time goal scoring record in the Bundesliga?', options: ['Robert Lewandowski', 'Harry Kane', 'Gerd Muller', 'Jadon Sancho'], correctAnswer: 'Gerd Muller', image: '/Images/Arsenal/DeclanRice21.png', gettyImage: '//embed.gettyimages.com/embed/1659766922?et=Q6Hu4fjeTMFIONz2QoUwWA&tld=com&sig=ik7p3ki1FQPV88ddCH_Rabx4-LGJqEBm0njATVugy78=&caption=false&ver=1' },
      { id: 3, question: 'Which player has the most Bundesliga appearances?', options: ['Robert Lewandowski', 'Thomas Muller', 'Manuel Neuer', 'Charly Korbel'], correctAnswer: 'Charly Korbel', image: '/Images/Arsenal/Xhaka.jpg', gettyImage : '//embed.gettyimages.com/embed/1580959529?et=gyE4A83FQ01gNs0-9eEk9g&tld=com&sig=yJwq7IAyaIj4xElh8giExSnRWArBU8E2FsRqJBPbiYw=&caption=false&ver=1'},
      { id: 4, question: 'Which local team to Borussia Dortmind\'s is considered their biggest rivals?', options: ['Bayern Munich', 'Schalke 04', 'Bayer Leverkusen', 'RB Leipzig'], correctAnswer: 'Schalke 04', image: '/Images/Arsenal/EmiratesStadiumLowerRes.jpg', gettyImage : '//embed.gettyimages.com/embed/1656999023?et=48VR3LGJQldaHrr8UgYlpQ&tld=com&sig=7ooyt2HFPk-dni6MC1dUu4o82WpIb-CxPkR9I5ghf-A=&caption=false&ver=1' },
      { id: 5, question: 'Which player is the youngest ever to play in the Bundesliga, making his debut the day after his 16th birthday?', options: ['Erling Haaland', 'Jadon Sancho', 'Thomas Muller', 'Youssouta Moukoko'], correctAnswer: 'Youssouta Moukoko', image: '/Images/Arsenal/ArsenalVsQuestion21.jpg', gettyImage : '//embed.gettyimages.com/embed/1657733084?et=ZBeqY0PpTfxqoxTRnA8qkA&tld=com&sig=veawrwvmq200jMIa_xrMVxCakTBd3WXKD-D55kvMsdk=&caption=false&ver=1' },
      { id: 6, question: 'Which manager has the most Bundesliga titles?', options: ['Pep Guardiola', 'Jurgen Klopp', 'Otto Rehhagel', 'Udo Lattek'], correctAnswer: 'Udo Lattek', image: '/Images/Arsenal/ArseneWenger.jpg', gettyImage : '//embed.gettyimages.com/embed/1589289271?et=gf3N_USRRQlm0HD8BQ2dHQ&tld=com&sig=cBfbM14aPc7FYvFPVKuz0qB0FOXy5jxFCWFrHfnl3Ws=&caption=false&ver=1' },
      { id: 7, question: 'Which player commanded the biggest transfer fee when moving to the Bundesliga?', options: ['Harry Kane', 'Jude Bellingham', 'Erling Haaland', 'Robert Lewandowski'], correctAnswer: 'Harry Kane', image: '/Images/Arsenal/Invincibles.jpg', gettyImage : '//embed.gettyimages.com/embed/51219213?et=1QRed3f8TBFjGyf-odVlHQ&tld=com&sig=I5B_lTJvRcGvtpgjWCSfR6erFdCTrhJ3PTJJa2rNpzg=&caption=false&ver=1' },
      { id: 8, question: 'Which Bundesliga team has the highest capacity stadium?', options: ['Bayern Munich', 'Vfb Stuttgart', 'RB Leipzig', 'Borrusia Dortmund'], correctAnswer: 'Borrusia Dortmund', image: '/Images/Arsenal/FaCup.jpg', gettyImage : '//embed.gettyimages.com/embed/1460096742?et=XvoHKKifTKZYzAmOLHuASQ&tld=com&sig=8Jf3fewW8M8TmLxP7eg4B2Gr0sCjmO9TfZ8oZWMT2i8=&caption=false&ver=1' },
      { id: 9, question: 'Which Bundesliga team has the most UEFA Champions League trophies?', options: ['Borussia Dortmund', 'Hamburger SV', 'Bayern Munich', 'Vfb Stuttgart'], correctAnswer: 'Bayern Munich', image: '/Images/Arsenal/Premier LeagueLowerRes.jpg', gettyImage : '' },
      { id: 10, question: 'Which of these teams has NOT won the Bundesliga?', options: ['Werder Bremen', 'Hamburger SV', 'Bayer Leverkusen', 'FC Koln'], correctAnswer: 'Bayer Leverkusen', image: '/Images/Arsenal/ArsenalFans.jpg', gettyImage : '' }
    ]
  ];


const Quiz = () => {
    const { quizType } = useParams();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [questionsData, setQuestionsData] = useState([]);

    const retryQuizLink = "/quiz/" + quizType;
  
    useEffect(() => {
      // Load questions based on the quizType when the component mounts
      setQuestionsData(questions[quizType - 1]);
    }, [quizType]);
  
    const handleAnswerSubmit = (selectedAnswer) => {
      setUserAnswers([...userAnswers, { questionId: currentQuestion, selectedAnswer }]);
      // Move to the next question or show the summary if it's the last question
      setCurrentQuestion(currentQuestion + 1)
    }

    const reloadPage = () => {
        window.location.reload();
    };

  
    return (
      <div className='quiz-page-container'>
        <div className='main-quiz-mobile-top-button'>
                <Link to='/'>
                    <button>Click Here To Try Another Quiz!</button>
                </Link>
            </div>
      <div className='quiz-container'>
        {currentQuestion < questionsData.length ? (
          <Question
            questionData={questionsData[currentQuestion]}
            onSubmit={handleAnswerSubmit}
          />
        ) : (
          <Summary questionsData={questionsData} userAnswers={userAnswers} />
        )}
      </div>
      <div className='main-quiz-mobile-bottom-button'>
                <Link to={retryQuizLink}>
                    <button onClick={reloadPage}>Retry This Quiz!</button>
                </Link>
                <Link to='/'>
                    <button>Back To Home</button>
                </Link>
            </div>
      </div>
    );
  };
  
  export default Quiz;