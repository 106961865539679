import React from 'react';

const PrivacyPage = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum dapibus facilisis arcu,
        euismod auctor mi vulputate ut. Etiam tempus quam eu quam elementum, vel rutrum felis
        tincidunt. Sed gravida sem eget ex posuere, vel sagittis libero fringilla.
      </p>
      <p>
        Phasellus commodo augue vel neque dignissim, et viverra mi convallis. Ut sollicitudin erat
        nec elit consectetur posuere. Proin id mi tellus. Nam lacinia consectetur nulla, vel posuere
        sapien malesuada nec.
      </p>
      {/* Add more content as needed */}
    </div>
  );
};

export default PrivacyPage;