import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import HomePage from './Components/HomePage';
import NavigationBar from './Components/NavigationBar';
import TeamQuizSelector from './Components/TeamQuizSelector';
import PrivacyPage from './Components/Privacy';
import Quiz from './Components/Quiz';

function App() {

  return (
    <div>
    <Router>
      <NavigationBar/>
      <div className='App'>
      <Routes>
        <Route path="/PremTeamQuizSelector" element={<TeamQuizSelector/> }/>
        <Route path="/Privacy" element={<PrivacyPage/>}/>
        <Route path="/" element={<HomePage/> }/>
        <Route path="/quiz/:quizType" element={<Quiz/> }/>
        
      </Routes>
        {/*(<Footer/>*/}
      </div>
    </Router>
    </div>
  );
}

export default App;
