import React, { useState } from 'react';
import './TeamQuizSelector.css';
import { useNavigate } from 'react-router-dom';

const TeamQuizSelector = () => {
  const [selectedTeam, setSelectedTeam] = useState('');
  const navigate = useNavigate();

  const teams = [
    { id: '1', name: 'Team 1', image: '/Images/TeamLogos/LRArsenalLogo.png'},
    { id: '2', name: 'Team 2', image: '/Images/TeamLogos/LRAstonVillaLogo.png'},
    { id: '3', name: 'Team 3', image: '/Images/TeamLogos/LRBournemouthLogo.png'},
    { id: '4', name: 'Team 4', image: '/Images/TeamLogos/LRBrentfordLogo.png'},
    { id: '5', name: 'Team 5', image: '/Images/TeamLogos/LRBrightonLogo.png'},
    { id: '6', name: 'Team 6', image: '/Images/TeamLogos/LRBurnleyLogo.png'},
    { id: '7', name: 'Team 7', image: '/Images/TeamLogos/LRChelseaLogo.png'},
    { id: '8', name: 'Team 8', image: '/Images/TeamLogos/LRCrystalPalaceLogo.png'},
    { id: '9', name: 'Team 9', image: '/Images/TeamLogos/LREvertonLogo.png'},
    { id: '10', name: 'Team 10', image: '/Images/TeamLogos/LRFulhamLogo.png'},
    { id: '11', name: 'Team 11', image: '/Images/TeamLogos/LRLiverpoolLogo.png'},
    { id: '12', name: 'Team 12', image: '/Images/TeamLogos/LRLutonLogo.png'},
    { id: '13', name: 'Team 13', image: '/Images/TeamLogos/LRManCityLogo.png'},
    { id: '14', name: 'Team 14', image: '/Images/TeamLogos/LRManUnitedLogo2.png'},
    { id: '15', name: 'Team 15', image: '/Images/TeamLogos/LRNewcastleLogo.png'},
    { id: '16', name: 'Team 16', image: '/Images/TeamLogos/LRForestLogo.png'},
    { id: '17', name: 'Team 17', image: '/Images/TeamLogos/LRSheffieldUnitedLogo.png'},
    { id: '18', name: 'Team 18', image: '/Images/TeamLogos/LRSpursLogo.png'},
    { id: '19', name: 'Team 19', image: '/Images/TeamLogos/LRWestHamLogo.png'},
    { id: '20', name: 'Team 20', image: '/Images/TeamLogos/LRWolvesLogo.png'},
    // Add more team objects with id, name, and image properties as needed
  ];

  const handleTeamClick = (teamId) => {
    setSelectedTeam(selectedTeam === teamId ? '' : teamId);
  };

  const handleSubmit = () => {
    if (selectedTeam) {
      // Perform the necessary action on form submission
      navigate(`/quiz/${selectedTeam}`);
    } else {
      // Show an error message or perform other actions if necessary
      console.log('Please select both team and difficulty');
    }
  };

  const getClassName = (teamId) => {
    if(teamId === 16 || teamId === 18)
    {
      return 'non-standard-team-image';
    }
    else {
      return '';
    }
  }

  return (
    <div className='team-quiz-selector-container'>
      <div className='title-container'>
        <img className='title-image' alt="title text contained" src="/Images/TitleTextImage.png"/>
      </div>
      
      <div className='team-buttons-container'>
        <div className="team-buttons">
          {teams.map((team) => (
            <button
              key={team.id}
              className={`team-button ${selectedTeam === team.id ? 'selected' : ''}`}
              onClick={() => handleTeamClick(team.id)}
            >
                <img className={getClassName(team.id)} src={team.image} alt={team.name} loading='lazy'/>
            </button>
          ))}
        </div>
          </div>
      
      <div>
        {/*
        <h2 className='select-heading'>Select Difficulty:</h2>
        <div className="difficulty-buttons">
          {difficulties.map((difficulty) => (
            <button
              key={difficulty}
              className={`difficulty-button ${selectedDifficulty === difficulty ? 'selected' : ''}`}
              onClick={() => handleDifficultyClick(difficulty)}
            >
              {difficulty}
            </button>
          ))}
        </div>*/}
        
      </div>
      <div className="submit-button">
        <img onClick= {handleSubmit} alt="submit button text" src='/Images/KickOff.png'/>
      </div>
    </div>
  );
};

export default TeamQuizSelector;