import React, { useEffect, useState } from 'react';
import "./Summary.css"

const Summary = ({ questionsData, userAnswers }) => {

    const [score, setTotalScore] = useState(0);

    useEffect(() => {
      const calculateTotalScore = () => {
        let score = 0;
        for (let i = 0; i < questionsData.length; i++) {
          if (questionsData[i].correctAnswer === userAnswers[i].selectedAnswer) {
            score+= 1;
          }
        }
          setTotalScore(score);
        };
        calculateTotalScore();
    }, [questionsData, userAnswers])


    const getResultImage = () => {
        if(score < 3) {
          return '/Images/QuizResultImages/RedCardCartoon.png';
        } else if (score < 6)
        {
          return '/Images/QuizResultImages/YellowCardCartoon.png';
        } else if (score < 9){
          return '/Images/QuizResultImages/GreatStrike.jpg';
        }else {
          return '/Images/QuizResultImages/LRAgueroPic.png';
        }
      };

    const getResultText = () => {

        if(score < 3) {
          return 'Send Him Off';
        } else if (score < 6)
        {
          return 'Not a slammer, but you can stay on the pitch!';
        } else if (score < 9){
          return 'Great strike, top bins!';
        }else {
          return 'AGUEROOOOO! What a strike!';
        }
    };

    const getClassName = (isCorrectOption, isSelectedOption) => {
        if (isCorrectOption) {
          return 'correct-answer-option';
        } else if (isSelectedOption && !isCorrectOption) {
          return 'incorrect-answer-option';
        } else {
          return 'regular-answer-option';
        }
      };

  return (
    <div className='quiz-result-container'>
        <div className='score-display-container'>
            <p>{score}/10, {getResultText()}</p>
            <img src={getResultImage()} alt=''/>
        </div>
      <ul>
        {questionsData.map((question, index) => (
          <li key={index} className={question.correctAnswer === userAnswers[index] ? 'correct-answer' : 'incorrect-answer'}>
            <p>{question.question}</p>
            <ul className="answer-list">
              {question.options.map((option, i) => {
                const isCorrectOption = option === question.correctAnswer;
                const isSelectedOption = option === userAnswers[index].selectedAnswer;
                const className = getClassName(isCorrectOption, isSelectedOption);
                return (
                  <li key={i} className={className}>
                    {option}
                    {isCorrectOption && isSelectedOption && (
                      <span className="correct-answer-icon">✔</span>
                    )}
                    {!isCorrectOption && isSelectedOption && (
                      <span className="incorrect-answer-icon">❌</span>
                    )}
                  </li>
                );
              })}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Summary;